const LoginQues = {
  title: '扫码登录相关问题',
  childList: [
    {
      title: '为什么要绑定微信号? ',
      text: '推品小助手需要绑定一个微信号，通过这个微信号才能把素材转发到各个群内，一个店铺的推品小助手只能绑定一个微信号，并且建议用微信小号扫码'
    },
    {
      title: '为什么点进去后二维码没有刷新出来？ ',
      text: '在这个页面一般需要等待2-3分钟，如果超时后还是无法刷新出来，建议切换网络退出APP，并重新进入入口尝试一下'
    },
    {
      title: '为什么扫码之后显示异地登录，会不安全吗？',
      text: '扫码成功之后，显示异地登录的是数据模拟的iPad端，推品小助手仅保存推品数据和相关设置，这是安全的'
    },
    {
      title: '微信出现安全提醒“群二维码已经失效”是什么意思？',
      text: '这是在绑定播货群的时候就会出现的提醒，只要有开通绑定的操作就会出现此提醒，是为了让推品小助手能够成功识别到这个群进行播货'
    },
    {
      title: '扫码后显示空白页面是怎么回事？',
      text: '这是微信登录规则的限制，要摄像头对着二维码扫码才可以登录，不能长按识别，也不能截图保存到相册再扫码，可以使用另一个手机拍照后扫码，并且需要在3分钟内扫码才可登录'
    },
    {
      title: '为什么扫码进去显示的是"宫姣栋"这个人？',
      text: '这是首次进入时可能出现的情况，是正常现象，多刷新几次就会变成自己的号'
    },
    {
      title: '为什么点击海报/按钮后显示黑屏？',
      text: '这个是属于手机的适配性问题，建议更换手机登录店主APP'
    },
    {
      title: '为什么扫码后会显示“你的账号无法通过扫码方式登录其他设备，可通过密码、手机验证码、QQ等方式登陆该设备”？',
      text: '因为此微信号被微信风控限制了，无法通过扫码登录其他设备，可以先尝试去微信团队进行解封，或者换一个微信号绑定小助手'
    },
    {
      title: '为什么扫码后会显示“设备已被别人绑定+一串ID”？',
      text: '因为现在扫码使用的这个微信号，已经绑定了其他店铺的小助手，麻烦先把其他店铺进行解绑操作才可以\n（一个微信号只能绑定一个店铺的小助手，换店铺绑定需要先去解绑上个店铺）'
    },
    {
      title: '为什么扫码登陆后，过一会显示的“iPad登陆”状态就会自动掉线？',
      text: '此种情况是因为刚才扫码的这个微信号已经绑定了另一个店铺的小助手，所以自动被踢下线了，需要先解绑才能扫码绑定其他店铺'
    },
    {
      title: '为什么扫码后会显示其他名字，如“周霜的iPad”？',
      text: '这是正常现象，此部分的名字是模拟出来的，不会影响小助手的正常使用'
    },
    {
      title: '为什么会显示这个界面“上线失败，返回了空数据...”等文字？',
      text: '检查一下微信那里是否还有显示“iPad登陆”，如果显示的话需要先把微信上从“iPad登陆”状态退出，然后再重新扫码'
    },
    {
      title: '必须用微信小号扫码登陆吗？没有微信小号怎么办 ',
      text: '由于需要经常发送信息、图片和链接等，对微信号会有一定的影响，所以建议使用微信小号进行绑定，没有小号的话最好开通一个'
    },
    {
      title: '为什么加载二维码时出现“不能降版登录”或者“获取登陆二维码失效-1”的提醒',
      text: '退出当前提醒页面后，重新进入，并点击“解绑此微信号”解除绑定，再重新扫码登录就可以了'
    }
  ]
}

const SetQues = {
  title: '操作设置相关问题',
  childList: [
    {
      title: '最多可以托管多少个群？ ',
      text: '最多可以托管3个群 '
    },
    {
      title: '如果领取了小助手到群里，后续不想用了，要怎么操作？ ',
      text: '停用的话，重新进入小助手设置页面，然后点击右上角注销即可'
    },
    {
      title: '怎么变更绑定的微信号? ',
      text: '在小助手界面解绑/注销后，便可以重新绑定其他微信号 '
    },
    {
      title: '为什么添加托管群的时候，一直搜不到要托管/采集的群？',
      text: '小助手加载微信群需要一段时间，先等一段时间后再多刷新几遍，并建议使用群名称比较简单的关键字/词进行搜索\n' +
        '如果还是搜索不到，要确认一下绑定小助手的微信是否在群内。如果确认在群内的话，可以在需要托管的群内说几句话或者发些表情，稍等几分钟后再重新去搜索'
    },
    {
      title: '如果不想在某个时间段推送，要怎么做？',
      text: '可以更改推送时段，或者不想推送的时间可以把托管群关闭播货 '
    },
    {
      title: '设置关键词回复后，如果顾客问到多个关键词为什么只回了一个关键词的内容？',
      text: '如果遇到顾客咨询的问题包含多个关键词，是会优先回复先创建的关键词，不会同时触发的 '
    },
    {
      title: '如果手机关机了没有退出iPad端，还会继续推送吗？ ',
      text: '是会继续推送的，如果不想推送的话可以在推品小助手的首页关闭托管'
    },
    {
      title: '为什么绑定小助手的微信号显示已离线，又弹出说明“您是在线的，无需重复登录”？',
      text: '这种情况是因为异常退出导致设备没有识别到，以微信号是否有登录iPad端为准，如果退出了就重新扫码登陆即可'
    },
    {
      title: '为什么想开通的群会显示“已开通”？',
      text: ' 第一种情况：若只显示“已开通”，是这边把该群设置成为了采集对象群，所以无法开通为托管群 \n' +
        '第二种情况：若显示“已开通+机器人：XXX”，该群已被其他店铺设置为托管群，一个群内无法同时设置两个小助手转播，可以检查一下其他店铺的群开通情况'
    },
    {
      title: '为什么店主托管群聊的时候显示“开群失败，机器人不在群内”？',
      text: '首先检查一下绑定小助手的微信号是否在群内，如果核实在的话就尝试刷新一下试试，还不行的话就说明小助手无法读取此群，需要把微信号退群后重新进群，稍等一段时间后再去刷新设置，而且小助手读取群信息需要一定时间'
    },
    {
      title: '为什么弹窗会显示“保存关键词回复失败，缺少生效群”？',
      text: '要先在首页设置绑定托管的群，然后在该页面生效群里面勾选需要关键词回复的群，最后点保存即可'
    },
    {
      title: '如何在已绑定但未登录（离线）状态下注销小助手？',
      text: '在小助手离线状态下，点击进入绑定入口，会先显示一个解绑界面，可以点击“解绑此微信号”进行注销解绑'
    }
  ]
}

const CopyWriting = {
  title: '素材文案相关问题',
  childList: [
    {
      title: '推品的文案是谁写的？会不会很官方？',
      text: '我们的选品文案都是由公司聘请专业的文案师去编辑的，会根据商品的卖点、性质和品牌背景去进行编辑，文案风格接地气，后期也会根据反馈和建议不断进行调整修改 '
    },
    {
      title: '官方素材库里有不想推送的素材怎么办？',
      text: '在官方素材库看到不想推送的，可以进行删除，也可以点击预览按钮看到发送后的样子，但是部分素材组无法进行删除'
    },
    {
      title: '小助手发的链接是谁的店铺链接？',
      text: '小助手绑定的是领取的店铺，所以发送的链接是领取店铺的链接 '
    },
    {
      title: '加价会同步吗？',
      text: '一般是会同步的（新增加官方素材的“热卖好货”需要按提醒设置加价）'
    },
    {
      title: '为什么小助手发出去的官方素材的小程序链接不显示价格？ ',
      text: '因为每个店铺的加价可能有所不同，小助手无法获取到每个人的店铺加价，所以官方素材的链接一般是不带价格的（新增加官方素材的“热卖好货”是可以显示价格）'
    },
    {
      title: '推品小助手的推品顺序可以改吗？',
      text: '不可以的哦，只能按照预览的顺序发的呢'
    }
  ]
}

const OtherQues = {
  title: '风控其他相关问题',
  childList: [
    {
      title: '为什么绑定了小助手的微信号在使用一段时间后被封号了？',
      text: '如果绑定的微信号权重不高，本身是有封号危险，再加上用小助手经常发送消息，是会有可能被封号，建议申诉解封后暂时不要用这个微信号绑定小助手，可以换个微信号绑定'
    },
    {
      title: '为什么只能绑定3个群，不能更多吗？ ',
      text: '目前因为VX风控比较严格，增加群数量的话，会同步提高封号风险，所以目前暂时只支持3个 '
    },
    {
      title: '扫码绑定小助手的微信号需要是店主吗？',
      text: '不需要的'
    },
    {
      title: '为什么小助手发了文案没有发小程序/商品链接？ ',
      text: '小程序链接在同一时间被分享了多次，可能会被VX风控识别并做出了限制，出现此种情况小助手一般会在3分钟内补发链接，如果被再次限制，可以尝试手动补发'
    }
  ]
}

const Material = {
  title: '素材采集相关问题',
  childList: [
    {
      title: '这个素材采集功能是用来干什么的？ ',
      text: '因为官方素材可能会与部分店主的顾客群体需求不适配，所以特地开放了素材采集功能，店主可以自行选择采集自己或者团队的素材群'
    },
    {
      title: '采集素材群后，一天可以推送多少条消息？有推送消息限制吗？ ',
      text: '这个是会有波动的，具体会根据当天VX风控的严格程度，实时调整消息条数的限制。为了避免风控封号，当推送条数超出当天限制时，推品小助手可能将不会再转发消息，以确保店主微信号的风控安全 '
    },
    {
      title: '为什么采集对象发了消息，但是小助手却没有在我的群内进行推送？',
      text: '首先受VX风控和转码限制，目前暂时不支持视频/公众号等消息的推送（小程序链接只能转发云货优选的）\n ' +
        '其次可以在【素材采集-推品时间段】内查看是否在推送的时间内，如果不在推送时间内是不会转发\n' +
        '最后是由于消息的类型不同可能会导致转发的时间会长一点，如果消息中有涉及敏感内容，那么该条消息是不会转发的，可以等待后续消息情况'
    },
    {
      title: '如果采集对象撤回的消息，转发到我的群里了还能撤回吗？',
      text: '需要在2分钟内手动进行撤回'
    },
    {
      title: '为什么使用采集功能，小助手发的商品小程序链接的显示价格和点开之后的商品显示的价格不同？',
      text: '小程序链接的分享页面因为技术原因，只会显示采集对象的价格，如果小助手转发到托管群内，该页面不会改变。但是顾客点开链接之后的价格会转化为你这边设置加价后的价格，因此可能导致链接分享页面和点开后的商品页面价格不一致 （请尽量保持和采集对象一样的加价，便可以避免此问题） '
    },
    {
      title: '采集的对象可以不是店主吗？',
      text: '不可以'
    },
    {
      title: '采集对象可以多选吗？',
      text: '因风控限制，采集对象只能选择一个'
    },
    {
      title: '为什么小助手转发的主会场/店铺，显示的是别的店主的名字？',
      text: '由于风控限制，这个页面由谁分享出来就会显示谁的主会场/店铺的名字，但是顾客点击进入之后只会进入你这边的店铺，不会串店的'
    },
    {
      title: '为什么设置好素材采集对象之后，小助手后续没有转发素材/商品链接？',
      text: '先检查采集对象是否正确，再检查一下绑定小助手的微信号是否还在采集群内（特别是之前小助手还有在正常发消息，某个时间点突然不发了的情况），如果都正常，可以尝试注销后重新设置一遍，再观察后续的消息情况'
    },
    {
      title: '为什么设置素材采集之后，小助手漏发了部分链接？',
      text: '如果图片文案都有正常发送，但是没有链接推送，这是因为小助手虽然有采集到，但是被VX风控屏蔽了。这种情况暂时是无法避免的，建议减少发送的频率以及手动补发，还有就是可以更换绑定小助手的微信号试试'
    },
    {
      title: '为什么设置素材采集对象的时候，提示“操作失败，机器人不在群内”？',
      text: '这是由于绑定小助手的微信号不在所要采集的群内或者小助手未识别到此群，' +
        '检查绑定小助手的微信号是否有在所要采集的群聊内，如果在的话，就退出群聊重新加入，再重新设置一遍小助手（注意：重新选择采集对象的时候需要点击同步按钮再设置）'
    }
  ]
}
export default [
  LoginQues,
  SetQues,
  CopyWriting,
  OtherQues,
  Material
]
