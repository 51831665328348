<template>
	<div class="mt-3 color-gray wrapper">
		<p class="fs-12">在刚开始使用推品小助手的过程中，大家会经常遇到一些问题，因此整理了部分常见的问题并且在此进行解答汇总</p>
		<div class="question_box" v-for="item in questionAndAnswerList" :key="item.title">
			<h2 class="question_title color-primary">{{ item.title }}</h2>
			<ul>
				<li class="question_item flex" v-for="(child, childIndex) in item.childList" :key="child.title"
				    @click="handleShow(child)">
					<span class="question_index">Q{{ childIndex + 1 }}：</span>
					<p class="flex-1">{{ child.title }} </p>
				</li>
			</ul>
		</div>
		<ShowAnswerPopup v-model="showAnswerPopupVisible" :answer-context="answerContext"/>
	</div>
</template>

<script>
import loginQues from './questionAndAnswerList'
import { defineAsyncComponent } from 'vue'

export default {
	name: 'question',
	components: {
		ShowAnswerPopup: defineAsyncComponent(() => import('./TheShowAnswerPopup'))
	},
	data() {
		return {
			questionAndAnswerList: Object.freeze(loginQues),
			showAnswerPopupVisible: false,
			answerContext: {}
		}
	},
	methods: {
		handleShow(val) {
			this.showAnswerPopupVisible = true
			this.answerContext = val
		}
	}
}
</script>

<style scoped lang="scss">
.wrapper {
	width: 345px;
	padding-bottom: px(60)
}

.question_box {
	padding: px(15);
	margin-top: px(12);
	background-color: white;
	box-shadow: 0 0 px(8) 0 rgba(0, 0, 0, 0.06);
	border-radius: px(4);

	&:first-of-type {
		margin-top: px(15);
	}

	.question_item {
		margin-top: px(12);
		color: #433C3C;
		align-items: baseline;

		.question_index {

		}
	}
}
</style>
